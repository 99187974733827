/**
 * @module Agent Status Hooks
 * @description
 */
import { getMoment, parseTimeString, getDayOfWeek } from '@/js/utils/time';

/**
   * @function checkStatus
   * @description Compare datetime to see if open
   */
const checkStatus = ( today ) => {
  if ( today && today.openTime !== undefined && today.closeTime !== undefined ) {
    // Parse the time string
    const openTime = parseTimeString( today.openTime );
    const closeTime = parseTimeString( today.closeTime );

    // number of month & day
    const date = new Date();
    const month = date.getMonth();
    const day = date.getDate();

    // Holiday hours for December
    if ( month === 11 ) {
      // 24th & 31st: Open at 8am EST, close at 6pm EST
      if ( day === 24 || day === 31 ) {
        openTime.hour = 8;
        closeTime.hour = 18;
      }

      // 25th: Closed
      if ( day === 25 ) {
        openTime.hour = 0;
        closeTime.hour = 0;
      }
    }

    // Compare time strings to current time.
    return {
      isOpen: getMoment().isBetween( openTime, closeTime, 'minute' ),
      openTime,
      closeTime
    };
  }
};

/**
   * @function fetchStatus
   * @description Get the hours of our call center
   */
const fetchStatus = ( openHours ) => {
  const today = openHours.find( ( hour ) => hour.dayOfWeek === getDayOfWeek() );
  return checkStatus( today );
};


/**
 * @function useAgentStatus
 * @description Our agent status API
 */
export const useAgentStatus = () => fetchStatus;

export default useAgentStatus;
